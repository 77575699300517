// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biases-in-hiring-process-js": () => import("./../../../src/pages/biases-in-hiring-process.js" /* webpackChunkName: "component---src-pages-biases-in-hiring-process-js" */),
  "component---src-pages-bullet-proof-hiring-js": () => import("./../../../src/pages/bullet-proof-hiring.js" /* webpackChunkName: "component---src-pages-bullet-proof-hiring-js" */),
  "component---src-pages-cheating-preemployment-test-js": () => import("./../../../src/pages/cheating-preemployment-test.js" /* webpackChunkName: "component---src-pages-cheating-preemployment-test-js" */),
  "component---src-pages-conversational-recruiting-js": () => import("./../../../src/pages/conversational-recruiting.js" /* webpackChunkName: "component---src-pages-conversational-recruiting-js" */),
  "component---src-pages-hire-right-intern-js": () => import("./../../../src/pages/hire-right-intern.js" /* webpackChunkName: "component---src-pages-hire-right-intern-js" */),
  "component---src-pages-hiring-for-resumes-js": () => import("./../../../src/pages/hiring-for-resumes.js" /* webpackChunkName: "component---src-pages-hiring-for-resumes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pre-employment-test-js": () => import("./../../../src/pages/pre-employment-test.js" /* webpackChunkName: "component---src-pages-pre-employment-test-js" */),
  "component---src-pages-quality-of-hire-js": () => import("./../../../src/pages/quality-of-hire.js" /* webpackChunkName: "component---src-pages-quality-of-hire-js" */),
  "component---src-pages-time-to-hire-js": () => import("./../../../src/pages/time-to-hire.js" /* webpackChunkName: "component---src-pages-time-to-hire-js" */)
}

